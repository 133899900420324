'use client'

import { RiCloseFill, RiDashboardLine, RiHomeLine } from '@remixicon/react'
import { Button, Divider } from 'antd'
import Image from 'next/image'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import companyLogo from 'public/companyLogo.png'
import React from 'react'
import { useTranslation } from 'react-i18next'

import useDrawerState from '@/hooks/context/useDrawerState'
import useTourState from '@/hooks/context/useTourState'
import useAuth from '@/hooks/useAuth'
import useDbSettings from '@/hooks/useDbSettings'

import { logoIndexClx, showIndexHeaderText } from '@/branding-config'
import { COMPANY } from '@/constants/env'
import { generateUUID } from '@/utils'
import { cn } from '@/utils/clsx'

import Conversations from './Conversations'
import ProfileBadge from './ProfileBadge'

interface ChatSidebarContentProps {
  isDrawer?: boolean
  adminChatbot?: boolean
}

const ChatSidebarContent: React.FC<ChatSidebarContentProps> = ({
  isDrawer,
  adminChatbot,
}) => {
  const pathname = usePathname()
  const { t } = useTranslation()
  const { close, setSelectedConversation } = useDrawerState()
  const { settings } = useDbSettings()
  const { user } = useAuth()
  const { userTourRef4 } = useTourState()

  const isDisabled = () => {
    if (!settings?.requireSubscription) {
      return false
    } else {
      return !user?.subscribed
    }
  }

  return (
    <div className='flex !h-screen flex-col justify-between gap-1 overflow-hidden p-1.5'>
      <div className='flex h-[calc(100%-62px)] flex-col gap-1'>
        {!adminChatbot && (
          <div className='flex w-full items-center justify-between p-3'>
            <Link
              href='/'
              onClick={() => pathname !== '/'}
              className='flex items-center gap-3'
            >
              <Image
                src={companyLogo}
                alt='company logo'
                className={cn('cursor-pointer', logoIndexClx)}
              />
              {showIndexHeaderText && (
                <div className='flex flex-col text-left'>
                  <span className='text-xl font-bold text-on-surface dark:text-dark-on-surface'>
                    {COMPANY}
                  </span>
                </div>
              )}
            </Link>
            {isDrawer && (
              <Button
                type='text'
                onClick={close}
                icon={<RiCloseFill className='size-5' />}
              />
            )}
          </div>
        )}
        <div ref={userTourRef4}>
          {!isDisabled() && (
            <>
              {user?.role === 'ADMIN' ? (
                <Link href='/admin/dashboard' target='_blank'>
                  <Button
                    type='text'
                    className='flex w-full !justify-start'
                    icon={<RiDashboardLine className='size-4' />}
                  >
                    {t('admin-dashboard')}
                  </Button>
                </Link>
              ) : (
                <Link href='/user/documents' target='_blank'>
                  <Button
                    type='text'
                    className='flex w-full !justify-start'
                    icon={<RiDashboardLine className='size-4' />}
                  >
                    {t('user-dashboard')}
                  </Button>
                </Link>
              )}
            </>
          )}
        </div>
        <Button
          type='text'
          className='flex w-full !justify-start'
          onClick={() => {
            close()
            setSelectedConversation(generateUUID())
          }}
          icon={<RiHomeLine className='size-4' />}
        >
          {t('conversations-new')}
        </Button>
        <Divider plain className='my-2' />
        <Conversations onClose={close} />
      </div>
      {!adminChatbot && <ProfileBadge />}
    </div>
  )
}

export default ChatSidebarContent
