'use client'

import { getCookie } from 'cookies-next'
import { useEffect, useRef } from 'react'

import useDrawerState from '@/hooks/context/useDrawerState'

import ChatLayout from '@/components/ChatLayout'
import Matcher from '@/components/Matcher'

import { switchLanguage } from '@/i18n'

const Home = () => {
  // Set language from cookie
  const lang = getCookie('locale')
  if (lang) {
    setTimeout(() => switchLanguage(lang))
  }
  const { selectedConversation } = useDrawerState()
  const ideaRef = useRef<string>('')
  useEffect(() => {
    ideaRef.current = ''
  }, [selectedConversation])

  return (
    <ChatLayout>
      <Matcher ideaRef={ideaRef} />
    </ChatLayout>
  )
}

export default Home
